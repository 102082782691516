<template>
  <div>
    <b-modal
      id="modal-edit-user"
      scrollable
      cancel-variant="outline-secondary"
      ok-variant="primary"
      :hide-footer="!$can('edit', 'staff')"
      centered
      :ok-title="$t('Save change')"
      :cancel-title="$t('Close')"
      size="md"
      :title="$t('Update user')"
      @hidden="resetModal()"
      @ok="confirmUpdateUser"
    >
      <b-overlay :show="show" variant="transparent" no-wrap />
      <!-- form -->
      <validation-observer ref="formUpdateUserModal">
        <b-form
          method="POST"
          class="auth-reset-password-form"
          @submit.prevent="validationForm"
        >
          <!-- Field: Name -->
          <b-form-group label-for="username">
            <label class="mb-1"
              ><span class="text-danger">*</span> {{ $t("Login Name") }}</label
            >
            <validation-provider
              #default="{ errors }"
              name="loginName"
              rules="required"
            >
              <b-form-input
                id="username"
                v-model="uData.username"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Field: Name -->
          <b-form-group label-for="fullName">
            <label class="mb-1"
              > {{ $t("Full Name") }}</label
            >
            <validation-provider
              #default="{ errors }"
              name="fullName"
              rules="required"
            >
              <b-form-input
                id="fullName"
                v-model="uData.fullName"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Field: role -->
          <b-form-group label-for="role">
            <label class="mb-1"
              ><span class="text-danger">*</span> {{ $t("Role") }}</label
            >
            <validation-provider
              #default="{ errors }"
              name="role"
              rules="required"
            >
              <v-select
                v-model="uData.roleId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="(val) => val.id"
                label="name"
                input-id="role"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Field: market -->
          <b-form-group label-for="market">
            <label class="mb-1"
              > {{ $t("Market") }}</label
            >
            <validation-provider
              #default="{ errors }"
              name="market"
              rules="required"
            >
              <v-select
                v-model="uData.marketId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countryLists"
                :reduce="(val) => val.id"
                label="name"
                input-id="market"
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Field: email -->
          <b-form-group label-for="email">
            <label class="mb-1"> Email</label>
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="uData.email"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="call_center_extension">
            <label class="mb-1"
              >{{ $t("callCenterExtension") }}</label
            >
            <validation-provider
              #default="{ errors }"
              name="call_center_extension"
            >
              <!-- rules="required|call_center_extension" -->
              <b-form-input
                id="call_center_extension"
                v-model="uData.call_center_extension"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label-for="call_center_extension">
            <label class="mb-1"
              >{{ $t("Ip Whitelist") }}</label
            >
            <validation-provider
              #default="{ errors }"
              name="call_center_extension"
            >
              <!-- rules="required|call_center_extension" -->
              <b-form-input
                id="call_center_extension"
                v-model="uData.ips"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Field: Status -->
          <b-form-group label-for="status">
            <label class="mb-1"
              ><span class="text-danger">*</span> {{ $t("Status") }}</label
            >
            <validation-provider
              #default="{ errors }"
              name="status"
              rules="required"
            >
              <v-select
                v-model="uData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(val) => val.value"
                input-id="status"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BOverlay,
  BTab,
  BTabs,
  BLink,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { formatRemoveTime } from "@core/utils/filter";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default {
  components: {
    BTab,
    BTabs,
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
      default: null,
    },
    roleOptions: {
      type: Array,
      required: false,
      default: null,
    },
    countryLists: {
      type: Array,
      default: null,
    },
    staffId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      uData: {
        username: "",
        fullName: "",
        roleId: "",
        marketId: 1,
        email: "",
        status: "",
        staffId: "",
        call_center_extension: "",
        ips:"",
        // birthday: '',
      },
      show: true,
    };
  },
  watch: {
    staffId: {
      deep: true,
      async handler(newVal) {
        if (newVal) {
          await this.getUserDetail(newVal);
          this.show = false;
        }
      },
    },
  },
  mounted() {},
  methods: {
    validationForm() {
      this.$refs.formUpdateUserModal.validate().then((success) => {
        if (success) {
          store
            .dispatch("app-member/updateUser", this.uData)
            .then((response) => {
              console.log(123123, response);
              if (response.status === 200) {
                if (response.data.code != "00") {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  });
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message
                        ? response.data.message
                        : "success",
                      icon: "CheckIcon",
                      variant: "success",
                    },
                  });
                  this.$emit("refetch-data");
                }
                this.$nextTick(() => {
                  setTimeout(() => {
                    this.$bvModal.hide("modal-edit-user");
                  }, "500");
                  setTimeout(() => {
                    this.resetModal();
                  }, "1000");
                });
              }
            })
            .catch((error) => {
              const { config, response } = error;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    async getUserDetail(id) {
      await store
        .dispatch("app-member/fetchUserDetail", id)
        .then((response) => {
          this.uData.staffId = id;
          this.uData.username = response.data.data.username;
          console.log(123, id);
          this.uData.fullName = response.data.data.full_name;
          this.uData.status = response.data.data.status;
          this.uData.email = response.data.data.email;
          this.uData.ips = response.data.data.ips;
          this.uData.call_center_extension =
            response.data.data.call_center_extension ? response.data.data.call_center_extension : '';
          this.uData.marketId = parseInt(response.data.data.market_id);
          // eslint-disable-next-line radix
          this.uData.roleId = parseInt(
            response.data.data.roles.map((item) => item.id).toString()
          );
          // this.uData.phone = response.data.data.phone
          // this.uData.birthday = formatRemoveTime(response.data.data.dob)
        });
    },
    resetModal() {
      this.$emit("update:staffId", null);
      this.show = true;
      this.uData = {
        username: "",
        fullName: "",
        roleId: "",
        marketId: 1,
        email: "",
        status: "",
        staffId: null,
        phone: "",
        birthday: "",
        call_center_extension: "",
        ips: "",
      };
    },
    confirmUpdateUser(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
  },
  setup() {
    const marketOptions = [
      { id: 1, name: "vietnam" },
      { id: 2, name: "Thailand" },
      { id: 3, name: "Japan" },
      { id: 4, name: "China" },
      { id: 5, name: "Korea" },
      { id: 6, name: "Australia" },
      { id: 7, name: "New Zealand" },
      { id: 8, name: "Other" },
    ];
    return {
      marketOptions,
    };
  },
};
</script>
