var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-edit-user","scrollable":"","cancel-variant":"outline-secondary","ok-variant":"primary","hide-footer":!_vm.$can('edit', 'staff'),"centered":"","ok-title":_vm.$t('Save change'),"cancel-title":_vm.$t('Close'),"size":"md","title":_vm.$t('Update user')},on:{"hidden":function($event){return _vm.resetModal()},"ok":_vm.confirmUpdateUser}},[_c('b-overlay',{attrs:{"show":_vm.show,"variant":"transparent","no-wrap":""}}),_c('validation-observer',{ref:"formUpdateUserModal"},[_c('b-form',{staticClass:"auth-reset-password-form",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"username"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Login Name")))]),_c('validation-provider',{attrs:{"name":"loginName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"disabled":""},model:{value:(_vm.uData.username),callback:function ($$v) {_vm.$set(_vm.uData, "username", $$v)},expression:"uData.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"fullName"}},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t("Full Name")))]),_c('validation-provider',{attrs:{"name":"fullName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fullName","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.uData.fullName),callback:function ($$v) {_vm.$set(_vm.uData, "fullName", $$v)},expression:"uData.fullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"role"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Role")))]),_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.roleOptions,"reduce":function (val) { return val.id; },"label":"name","input-id":"role"},model:{value:(_vm.uData.roleId),callback:function ($$v) {_vm.$set(_vm.uData, "roleId", $$v)},expression:"uData.roleId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"market"}},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t("Market")))]),_c('validation-provider',{attrs:{"name":"market","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.countryLists,"reduce":function (val) { return val.id; },"label":"name","input-id":"market","disabled":""},model:{value:(_vm.uData.marketId),callback:function ($$v) {_vm.$set(_vm.uData, "marketId", $$v)},expression:"uData.marketId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"email"}},[_c('label',{staticClass:"mb-1"},[_vm._v(" Email")]),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.uData.email),callback:function ($$v) {_vm.$set(_vm.uData, "email", $$v)},expression:"uData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"call_center_extension"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("callCenterExtension")))]),_c('validation-provider',{attrs:{"name":"call_center_extension"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"call_center_extension","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.uData.call_center_extension),callback:function ($$v) {_vm.$set(_vm.uData, "call_center_extension", $$v)},expression:"uData.call_center_extension"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"call_center_extension"}},[_c('label',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.$t("Ip Whitelist")))]),_c('validation-provider',{attrs:{"name":"call_center_extension"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"call_center_extension","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.uData.ips),callback:function ($$v) {_vm.$set(_vm.uData, "ips", $$v)},expression:"uData.ips"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"status"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Status")))]),_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":function (val) { return val.value; },"input-id":"status"},model:{value:(_vm.uData.status),callback:function ($$v) {_vm.$set(_vm.uData, "status", $$v)},expression:"uData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }